<template>
    <page-loading-animation v-if="isLoading" :is-loading="isLoading" />

    <!--Page Content-->
    <div v-else class="appGrey" style="min-height: 100%">
        {{ computedInit }}

        <!--Organisation-->
        <form-section-title title="Organisation" />
        <v-row no-gutters>
            <!--Name-->
            <v-col
                :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
                :cols="$vuetify.breakpoint.width < 600 ? 12 : 8"
            >
                <app-form-field
                    form-type="textInput"
                    :disabled="isReadOnly"
                    :error="errors.organisationName"
                    :error-message="errors.organisationNameErrorMessage"
                    label="Organisation Name"
                    v-model.trim="form.organisationName"
                />
            </v-col>

            <!--Organisation Status-->
            <v-col
                :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
                :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
            >
                <app-form-field
                    form-type="select"
                    :disabled="isReadOnly"
                    :items="['Active', 'Locked']"
                    label="Status"
                    v-model="form.organisationStatus"
                />
            </v-col>
        </v-row>

        <!--Default Organisation Zones-->
        <form-section-title
            class="mt-8"
            description="All new contacts will inherit these default zones, but they can be updated on a per-user basis. Only Admins can change defaults."
            title="Default Organisation Zones"
        />
        <v-row
            no-gutters
            style="
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-gap: 16px;
            "
        >
            <div
                v-for="item in form.organisationDefaultZones"
                :key="item.zone"
                class="d-flex appWhite flex-column align-center rounded-lg pa-1"
            >
                <app-text size="small">{{ item.zone }}</app-text>
                <app-icon
                    @click.native="handleZoneSelection(item)"
                    :class="
                        MIX_getCurrentUser().userLevel !== 'Staff-Admin'
                            ? 'cursorNotAllowed'
                            : ''
                    "
                    :color="item.hasAccess ? 'green' : 'red'"
                    :disabled="isReadOnly"
                    :icon="item.hasAccess ? 'success' : 'cancel'"
                    size="48"
                />
            </div>
        </v-row>

        <!--Organisation Manager-->
        <form-section-title class="mt-8" title="Managers" />

        <!--Managers Form - Only visible when New-->
        <v-row v-if="!form.entityId" no-gutters>
            <!--Manager Name-->
            <v-col
                :class="$vuetify.breakpoint.width < 768 ? 'mt-4' : 'pr-2'"
                :cols="$vuetify.breakpoint.width < 768 ? 12 : 6"
            >
                <app-form-field
                    form-type="textInput"
                    :disabled="isReadOnly"
                    :error="errors.contactUserName"
                    :error-message="errors.contactUserNameErrorMessage"
                    label="Contact Name"
                    v-model.trim="contactUserName"
                />
            </v-col>

            <!--Manager Position-->
            <v-col
                :class="$vuetify.breakpoint.width < 768 ? 'mt-4' : 'pl-2'"
                :cols="$vuetify.breakpoint.width < 768 ? 12 : 6"
            >
                <app-form-field
                    form-type="textInput"
                    :disabled="isReadOnly"
                    :error="errors.contactUserPosition"
                    :error-message="errors.contactUserPositionErrorMessage"
                    label="Contact Position"
                    v-model.trim="contactUserPosition"
                />
            </v-col>

            <!--Manager Email-->
            <v-col
                :class="$vuetify.breakpoint.width < 768 ? 'mt-4' : 'mt-4 pr-2'"
                :cols="$vuetify.breakpoint.width < 768 ? 12 : 6"
            >
                <app-form-field
                    form-type="textInput"
                    :disabled="!!form.entityId || isReadOnly"
                    :error="errors.contactUserEmail"
                    :error-message="errors.contactUserEmailErrorMessage"
                    label="Contact Email"
                    title="Email addresses cannot be changed at present."
                    v-model.trim="contactUserEmail"
                />
            </v-col>

            <!--Manager Telephone-->
            <v-col
                :class="$vuetify.breakpoint.width < 768 ? 'mt-4' : 'mt-4 pl-2'"
                :cols="$vuetify.breakpoint.width < 768 ? 12 : 6"
            >
                <app-form-field
                    form-type="textInput"
                    :disabled="isReadOnly"
                    :error="errors.contactUserTelephone"
                    :error-message="errors.contactUserTelephoneErrorMessage"
                    label="Contact Telephone"
                    v-model.trim="contactUserTelephone"
                />
            </v-col>
        </v-row>

        <!--Managers - Only visible when Existing-->
        <div v-if="form.entityId">
            <!--Table-->
            <v-data-table
                v-if="$vuetify.breakpoint.width >= 600"
                class="appWhite rounded-lg mt-4"
                :headers="computedHeaders"
                :items="computedTableData"
            >
                <!--ID-->
                <template v-slot:item.id="{ item }">
                    <app-text size="small">{{
                        item.userData.entityId
                    }}</app-text>
                </template>

                <!--UserName-->
                <template v-slot:item.userName="{ item }">
                    <app-text size="small">{{
                        item.userData.userName
                    }}</app-text>
                    <app-text color="grey9" size="small">{{
                        item.userData.userPosition
                    }}</app-text>
                </template>

                <!--Email-->
                <template v-slot:item.userEmail="{ item }">
                    <app-text size="small">{{
                        item.userData.userEmail
                    }}</app-text>
                </template>

                <!--Telephone-->
                <template v-slot:item.userTelephone="{ item }">
                    <app-text size="small">{{
                        item.userData.userTelephone
                    }}</app-text>
                </template>

                <!--Action Button-->
                <!--<template v-slot:item.action="{item}">-->
                <!--	<app-icon @click.native="openRightPanel(item)"-->
                <!--			  class="cursorPointer" color="primary" icon="arrowForward"/>-->
                <!--</template>-->
            </v-data-table>

            <!--Mobile Cards-->
            <div v-if="$vuetify.breakpoint.width < 600">
                <div
                    v-for="item in computedTableData"
                    :key="item.userData?.entityId"
                    class="appWhite rounded-lg pa-4"
                >
                    <!--UserName-->
                    <app-text>{{ item.userData.userName }}</app-text>

                    <!--Position-->
                    <app-text class="mt-2" color="grey9" size="small">{{
                        item.userData.userPosition
                    }}</app-text>
                </div>
            </div>
        </div>

        <!--Save Button-->
        <div class="d-flex justify-end mt-4">
            <app-btn
                v-if="!isReadOnly"
                @click.native="handleSaveItem"
                color="green"
                icon="save"
                label="Save"
            />
        </div>
    </div>
</template>

<script>
///////////////////////////////
//// ! Organisation Form ! ////
///////////////////////////////

export default {
    name: "OrganisationForm",

    props: ["formData", "isReadOnly"],

    data: () => ({
        contactUserName: "",
        contactUserEmail: "",
        contactUserPosition: "",
        contactUserTelephone: "",
        errors: {
            organisationName: false,
            organisationNameErrorMessage: "",
            contactUserName: false,
            contactUserNameErrorMessage: "",
            contactUserEmail: false,
            contactUserEmailErrorMessage: "",
            contactUserPosition: false,
            contactUserPositionErrorMessage: "",
            contactUserTelephone: false,
            contactUserTelephoneErrorMessage: "",
        },
        form: {
            entityId: "",
            organisationName: "",
            organisationStatus: "Active",
            organisationManagers: [],
            organisationDefaultZones: [
                { zone: "Zone 1", hasAccess: false },
                { zone: "Zone 2", hasAccess: false },
                { zone: "Zone 3", hasAccess: false },
                { zone: "Zone 4", hasAccess: false },
                { zone: "Zone 5", hasAccess: false },
            ],

            createdUserId: "",
            createdUserName: "",
            createdDateTime: 0,
            modifiedUserId: "",
            modifiedUserName: "",
            modifiedDateTime: 0,
            isDeleted: false,
            deletedUserId: "",
            deletedUserName: "",
            deletedDateTime: 0,
        },
        isLoading: false,
        tableHeaders: [
            {
                text: "ID",
                value: "entityId",
                align: "left",
                sortable: false,
                hidden: true,
            },
            { text: "Name", value: "userName", align: "left", sortable: false },
            {
                text: "Email",
                value: "userEmail",
                align: "left",
                sortable: false,
            },
            {
                text: "Telephone",
                value: "userTelephone",
                align: "left",
                sortable: false,
            },
            // {text: '', value: 'action', align: 'center', sortable: false, width: '48px'},
        ],
    }),

    computed: {
        /**
         * Computed Init
         *
         * If there is any form data, assign it to the form.
         * If there is no form data, it will be blank (new).
         */
        computedInit() {
            const t = this;
            const ORGANISATION_DATA = t.$props.formData?.organisationData;
            const USERS_DATA = t.$props.formData?.usersData;

            if (ORGANISATION_DATA?.entityId) {
                t.form = ORGANISATION_DATA;

                // Parse the default zones
                t.form.organisationDefaultZones =
                    typeof ORGANISATION_DATA.organisationDefaultZones ===
                    "string"
                        ? JSON.parse(ORGANISATION_DATA.organisationDefaultZones)
                        : ORGANISATION_DATA.organisationDefaultZones;

                // Set the contact user
                const contactUser = USERS_DATA.find(
                    (user) =>
                        user.userData.entityId ===
                        t.form.organisationManagers[0]
                );
                t.contactUserEmail = contactUser?.userData?.userEmail;
                t.contactUserName = contactUser?.userData?.userName;
                t.contactUserPosition = contactUser?.userData?.userPosition;
                t.contactUserTelephone = contactUser?.userData?.userTelephone;
            }
        },

        /**
         * Computed Headers
         *
         * Remove hidden headers and only show the actions column to those with access.
         *
         * @returns an array of header objects
         */
        computedHeaders() {
            const t = this;
            let headers = t.tableHeaders;

            // Remove hidden headers
            headers = headers.filter((h) => !h.hidden);

            return headers;
        },

        /**
         * Computed Table Data
         *
         * Sort and return the table data.
         *
         * @returns {array[{}]} an array of objects
         */
        computedTableData() {
            const t = this;
            const ORGANISATION_MANAGERS_IDS = t.form.organisationManagers;
            let tableData =
                t.$props.formData?.usersData?.filter((user) =>
                    ORGANISATION_MANAGERS_IDS.includes(user.userData.entityId)
                ) || [];

            tableData = tableData.sort((a, b) =>
                a.userData.userName > b.userData.userName ? 1 : -1
            );

            return tableData;
        },
    },

    methods: {
        /**
         * Check If User Email Exists
         *
         * Check if the user email already exists in the DB, and return a boolean.
         *
         * @param userEmail {string} - the user email to check
         * @returns {Promise<boolean>}
         */
        async checkIfUserEmailExists(userEmail) {
            const t = this;

            const RESPONSE = await t.MIX_redis_getUsersWhere([
                { whereKey: "userEmail", whereValue: userEmail },
            ]);

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error(
                    "Error checking if the user exists: ",
                    RESPONSE.errors
                );
                t.$sharedState.errorMessage =
                    "There was a problem checking if this user already exists, please try again.";
                return;
            }

            // Return a boolean if the user exists, or not
            return !!RESPONSE.data[0]?.entityId;
        },

        /**
         * Clear Errors
         *
         * Clear all errors and their messages.
         */
        clearErrors() {
            const t = this;

            for (const error in t.errors) {
                if (typeof t.errors[error] === "string") t.errors[error] = "";
                if (typeof t.errors[error] === "boolean")
                    t.errors[error] = false;
            }
        },

        /**
         * Create Item
         *
         * Create a new Organisation and Organisation Manager.
         *
         * @returns {Promise<void>}
         */
        async createItem() {
            const t = this;

            t.isLoading = true;

            // Check if the new Organisation-Manager's email already exists
            const DOES_EMAIL_EXIST = await t.checkIfUserEmailExists(
                t.contactUserEmail
            );
            if (DOES_EMAIL_EXIST) {
                t.isLoading = false;
                t.errors.contactUserEmail = true;
                t.errors.contactUserEmailErrorMessage =
                    "This email already exists, please use another.";
                return;
            }

            // Stringify the default zones as they are stored as a string in the DB
            t.form.organisationDefaultZones = JSON.stringify(
                t.form.organisationDefaultZones
            );

            const RESPONSE = await t.MIX_redis_create("organisation", t.form);

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error("Error creating Organisation: ", RESPONSE.error);
                t.isLoading = false;
                t.$sharedState.errorMessage =
                    "There was a problem creating the Organisation. Please try again.";
                return;
            }

            t.$sharedState.successMessage = "Created Organisation";

            const USER_RESPONSE = await t.MIX_redis_createRegisteredUser({
                userEmail: t.contactUserEmail,
                userLevel: "Organisation-Manager",
                userName: t.contactUserName,
                userOrganisation: RESPONSE.data.entityId,
                userPosition: t.contactUserPosition,
                userRole: "Manager",
                userTelephone: t.contactUserTelephone,
                userType: "Organisation",
            });

            // Handle any errors
            if (USER_RESPONSE.hasErrors) {
                console.error(
                    "Error creating Organisation Manager: ",
                    USER_RESPONSE.error
                );
                t.isLoading = false;
                t.$sharedState.errorMessage =
                    "There was a problem creating the Organisation Manager. Please try again.";
                return;
            }

            t.$emit("emitReloadPage");

            t.isLoading = false;

            t.$sharedState.successMessage = "Created Organisation Manager";
        },

        /**
         * Handle Zone Selection
         *
         * Set the zone access based on user selection.
         * Only available to Staff-Admin.
         *
         * @param item - the selected zone item
         */
        handleZoneSelection(item) {
            const t = this;

            // Return if the item is read only
            if (
                t.isReadOnly ||
                t.MIX_getCurrentUser().userLevel !== "Staff-Admin"
            )
                return;

            item.hasAccess = !item.hasAccess;
        },

        /**
         * Handle Save Item
         *
         * Save/Update the form to the DB, after it has passed validation.
         */
        async handleSaveItem() {
            const t = this;

            // Only continue if the form is valid
            if (!t.validateForm()) return;

            t.isLoading = true;

            // Create
            if (!t.form.entityId) await t.createItem();

            // Update
            if (t.form.entityId) await t.updateItem();
        },

        /**
         * Update Item
         *
         * Update an existing Organisation.
         *
         * @returns {Promise<void>}
         */
        async updateItem() {
            const t = this;

            t.isLoading = true;

            // Stringify the default zones as they are stored as a string in the DB
            t.form.organisationDefaultZones = JSON.stringify(
                t.form.organisationDefaultZones
            );

            const RESPONSE = await t.MIX_redis_update(
                "organisation",
                t.form.entityId,
                t.form
            );

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error("Error updating Organisation: ", RESPONSE.error);
                t.isLoading = false;
                t.$sharedState.errorMessage =
                    "There was a problem updating the Organisation. Please try again.";
                return;
            }

            t.$sharedState.successMessage = "Updated Organisation";

            t.isLoading = false;
            t.$emit("emitReloadPage");
        },

        /**
         * Validate Form
         *
         * Validate the form as required.
         *
         * @returns {boolean} if the form has passed validation, or not
         */
        validateForm() {
            const t = this;

            t.clearErrors();

            // Organisation Name
            if (!t.form.organisationName) {
                t.errors.organisationName = true;
                t.errors.organisationNameErrorMessage =
                    "Organisation Name is required";
            }

            // Organisation Manager User Name
            if (!t.contactUserName) {
                t.errors.contactUserName = true;
                t.errors.contactUserNameErrorMessage = "Name is required";
            }

            // Organisation Manager User Email
            const USER_EMAIL = t.contactUserEmail;
            if (!USER_EMAIL) {
                t.errors.contactUserEmail = true;
                t.errors.contactUserEmailErrorMessage = "Email is required";
            } else if (!t.MIX_isEmailValid(USER_EMAIL)) {
                t.errors.contactUserEmail = true;
                t.errors.contactUserEmailErrorMessage = "Email is not valid";
            }

            // Organisation Manager User Position
            if (!t.contactUserPosition) {
                t.errors.contactUserPosition = true;
                t.errors.contactUserPositionErrorMessage =
                    "Position is required";
            }

            // Organisation Manager User Telephone
            const USER_TELEPHONE_NUMBER = t.contactUserTelephone;
            if (USER_TELEPHONE_NUMBER) {
                const VALIDATED_NUMBER = t.MIX_isTelephoneNumberValid(
                    USER_TELEPHONE_NUMBER
                );
                if (!VALIDATED_NUMBER) {
                    t.errors.contactUserTelephone = true;
                    t.errors.contactUserTelephoneErrorMessage =
                        "Invalid Phone Number";
                } else {
                    t.contactUserTelephone = VALIDATED_NUMBER;
                }
            }

            return !Object.values(t.errors).includes(true);
        },
    },
};
</script>

<style scoped></style>
