<template>
    <div class="appGrey" style="min-height: 100%">
        {{ computedInit }}

        <!--Stewards-->
        <div>
            <!--Label-->
            <app-text size="medium-bold">Stewards</app-text>

            <!--Form-->
            <div class="d-flex align-center justify-space-between mt-4">
                <!--Stewards-->
                <app-form-field
                    form-type="autoComplete"
                    :disabled="!isStewardsEditable"
                    :items="computedStewardsTableData"
                    :item-text="
                        (item) =>
                            `${item.userName} (${item.userInternalIdNumber})`
                    "
                    label="Available Stewards"
                    :multiple="true"
                    :return-object="true"
                    :small-chips="true"
                    style="width: 100%"
                    v-model="tempTeamStewards"
                />

                <!--Save Button-->
                <app-btn
                    v-if="isStewardsEditable"
                    @click.native="saveStewards(tempTeamStewards)"
                    class="ml-4"
                    color="green"
                    :disabled="!tempTeamStewards.length"
                    icon="save"
                    label="Save"
                />

                <!--Edit Button-->
                <edit-icon
                    @click.native="editStewards"
                    :isActive="isStewardsEditable"
                />
            </div>

            <!--Table-->
            <v-data-table
                class="appWhite rounded-lg mt-4"
                :items="teamStewards"
                :headers="tableHeaders"
            >
                <!--Steward-->
                <template v-slot:item.userName="{ item }">
                    <app-text size="small"
                        >{{ item.userName }} ({{
                            item.userInternalIdNumber
                        }})</app-text
                    >
                </template>

                <!--Delete-->
                <template v-slot:item.delete="{ item }">
                    <app-icon
                        @click.native="removeSteward(item)"
                        class="cursorPointer pa-2"
                        color="red"
                        icon="delete"
                    />
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
///////////////////////////////
//// ! Team Stweard Form ! ////
///////////////////////////////

export default {
    name: "TeamStewardForm",

    props: ["formData", "isReadOnly", "usersData"],

    data: () => ({
        tableHeaders: [
            {
                text: "Name",
                value: "userName",
                sortable: false,
                align: "start",
            },
            {
                text: "Actions",
                value: "delete",
                sortable: false,
                align: "center",
                width: "10px",
            },
        ],

        isStewardsEditable: false,
        tempTeamStewards: [],
        teamStewards: [],
    }),

    computed: {
        /**
         * Computed Init
         *
         * If there is any form data, assign it to the form.
         */
        computedInit() {
            const t = this;
            const FORM_DATA = t.$props?.formData?.teamData;

            if (FORM_DATA?.entityId) {
                // Assign all the users for this team
                t.teamStewards = t.$props.usersData.filter(
                    (u) =>
                        (u.userLevel === "Steward-User" ||
                            u.userPayGrade === "Senior Supervisor") &&
                        u.userTeams.includes(FORM_DATA.entityId)
                );

                // Sort by userName
                t.teamStewards.sort((a, b) =>
                    a.userName > b.userName ? 1 : -1
                );
            }
        },

        /**
         * Stewards Table Data
         *
         * Return an array of Stewards that are not already assigned to this team.
         *
         * @returns {array} array of Stewards
         */
        computedStewardsTableData() {
            const t = this;
            let usersData = t.$props.usersData;

            // Get all the Stewards and Senior Supervisors that are not already assigned to this team.
            usersData = usersData.filter(
                (u) =>
                    (u.userLevel === "Steward-User" ||
                        u.userPayGrade === "Senior Supervisor") &&
                    !u.userTeams.includes(t.$props.formData.teamData.entityId)
            );

            // Sort by userName
            usersData.sort((a, b) => (a.userName > b.userName ? 1 : -1));

            return usersData;
        },
    },

    methods: {
        /**
         * Edit Steward
         *
         * Toggle the editability.
         */
        editStewards() {
            const t = this;

            t.isStewardsEditable = !t.isStewardsEditable;
        },

        /**
         * Reload Data
         *
         * Emit back to the parent (Teams.vue) to reload the data to update the page.
         */
        reloadData() {
            const t = this;

            t.$emit("emitReloadData");
        },

        /**
         * Remove Steward
         *
         * Remove the Steward from the current Team by updating their userTeams array.
         *
         * @param userData the user object to remove
         * @returns {Promise<void>}
         */
        async removeSteward(userData) {
            const t = this;

            // Remove the current team from the userTeams array
            userData.userTeams = userData.userTeams.filter(
                (ut) => ut !== t.$props.formData.teamData.entityId
            );

            const RESPONSE = await t.MIX_redis_update(
                "user",
                userData.entityId,
                userData
            );

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error("Error updating User: ", RESPONSE.error);
                return;
            }

            // Remove the user from the tempTeamStewards array to stop them showing up in the Available Stewards
            // selection field
            t.tempTeamStewards = t.tempTeamStewards.filter(
                (ts) => ts.entityId !== userData.entityId
            );

            t.$sharedState.successMessage = "Deleted Steward";
        },

        /**
         * Save Stewards
         *
         * Send the new teamStewards array off to the server to update the list of Stewards on this team.
         */
        async saveStewards(teamStewards) {
            const t = this;
            const FORM_DATA = t.$props?.formData?.teamData;

            // If there are no Stewards, return
            if (!t.tempTeamStewards.length) return;

            // Update Users
            const RESPONSE = await t.MIX_redis_updateUsersTeam(
                teamStewards,
                FORM_DATA.entityId
            );

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error("Error updating User: ", RESPONSE.error);
                return;
            }

            t.$sharedState.successMessage = "Updated Stewards";

            t.isStewardsEditable = false;

            t.reloadData();
        },
    },
};
</script>

<style scoped></style>
