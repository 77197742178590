import Vue from 'vue';
import Vuetify from 'vuetify';
import appConfig from '../appConfig';

Vue.use(Vuetify);

const opts = {
	theme: {
		options: { customProperties: true },
		themes: {
			light: {

				// App colors
				appGrey: '#f5f5f5',
				appWhite: '#FFFFFF',
				primary: appConfig.appBranding.primary,

				green: '#4CAF50',
				orange: '#ff9d2e',
				red: '#FF3D00',
				blue: '#2196F3',

				// Greys
				grey3: '#333333',
				grey6: '#666666',
				grey9: '#999999',
				greyD: '#DDDDDD',

				// Side Navigation colors
				navBackground: '#333333',
				navIcon: '#CC0E23',
				navIconActive: '#CC0E23',
				navText: '#999'

			}
		}
	}
}

export default new Vuetify(opts);
