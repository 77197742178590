<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<div v-else>

		<!--Header-->
		<div class="d-flex justify-space-between">

			<!--Title-->
			<page-title icon="eventsDashboardPre"
						info="Keep up to date with figures for each Team per Event."
						title="Pre Event Dashboard"/>

			<!--Event Stats-->
			<div class="d-flex">

				<!--Confirmed-->
				<pre-event-total-card v-if="$vuetify.breakpoint.width >= 840" card-label="Confirmed"
									  :card-value="computedEventFigures.numberConfirmed"
									  cardColor="green"/>

				<!--Available-->
				<pre-event-total-card v-if="$vuetify.breakpoint.width >= 960" card-label="Available"
									  :card-value="computedEventFigures.numberAvailable"
									  cardColor="orange"/>

				<!--Reserved-->
				<pre-event-total-card v-if="$vuetify.breakpoint.width >= 1080" card-label="Reserved"
									  :card-value="computedEventFigures.numberReserved"
									  cardColor="blue"/>

				<!--Unavailable-->
				<pre-event-total-card v-if="$vuetify.breakpoint.width >= 1200" card-label="Unavailable"
									  :card-value="computedEventFigures.numberUnavailable"
									  cardColor="red"/>

				<!--Required-->
				<pre-event-total-card v-if="$vuetify.breakpoint.width >= 1366" card-label="Not Required"
									  :card-value="computedEventFigures.numberNotRequired"
									  cardColor="grey"/>

				<!--Response-->
				<pre-event-total-card v-if="$vuetify.breakpoint.width >= 1520" card-label="No Response"
									  :card-value="computedEventFigures.numberNoResponse"
									  cardColor="grey3"/>

				<!--Balance-->
				<pre-event-total-card v-if="$vuetify.breakpoint.width >= 644" card-label="Balance"
									  :card-value="computedEventFigures.numberBalance"
									  :cardColor="computedEventFigures.numberBalance === 0 ? 'grey' : computedEventFigures.numberBalance < 0 ? 'red' : 'green'"/>

			</div>

		</div>

		<v-divider class="my-4"/>

		<!--Event | Filters | More Actions-->
		<div class="d-flex align-center mt-4">

			<!--Event Selection-->
			<app-form-field form-type="autoComplete"
							class="mr-4"
							:items="eventsData"
							item-text="eventName"
							label="Select an Event"
							:return-object="true"
							v-model="selectedEvent"/>

			<!--Filters-->
			<app-form-field v-if="selectedEvent.entityId"
							form-type="select"
							class="mr-4"
							:clearable="true"
							:deletable-chips="true"
							:items="computedTeamsData"
							item-text="teamName"
							label="Filter"
							:multiple="true"
							prepend-inner-icon="icons8-slider primary--text"
							:return-object="true"
							:small-chips="true"
							v-model="filterByTeams"/>

			<v-spacer/>

			<!--More Actions Menu-->
			<!--<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>-->

		</div>

		<!--Getting Started Info-->
		<div v-if="!computedCardsData.length" class="d-flex align-center justify-center"
			 :style="$vuetify.breakpoint.width < 600 ? 'height: 50vh' : 'height: 50vh'">
			<div>
				<page-start-information title="What do you want to do?"
										:points="['Select a Type to get started']"/>
			</div>
		</div>

		<!--Team Cards-->
		<div class="preEventDashboard-cardGrid mt-4">

			<pre-event-team-card v-for="item in computedCardsData" :key="item.entityId"
								 :cardData="item"/>

		</div>

	</div>

</template>

<script>
import PreEventTeamCard from "@/views/preEventDashboard/preEventTeamCard/PreEventTeamCard";
import PreEventTotalCard from "@/views/preEventDashboard/preEventTotalCard/PreEventTotalCard";

export default {

	name: "PreEventDashboard",

	components: {PreEventTotalCard, PreEventTeamCard},

	data: () => ({
		filterByTeams: [],
		isLoading: true,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'}
		],
		refreshInterval: null,
		selectedEvent: {},

		// Data
		eventsData: [],
		eventTeamsData: [],
		eventTeamsFiguresData: [],
		teamsData: [],
	}),

	computed: {

		/**
		 * Computed Cards Data
		 *
		 * Sort and return the card data. If there are any filters, also computed them.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedCardsData() {
			const t = this
			let cardsData = []
			const EVENT_TEAMS_DATA = t.eventTeamsData
			const EVENT_TEAMS_FIGURES_DATA = t.eventTeamsFiguresData
			const TEAMS_DATA = t.computedTeamsData

			// Loop over the EventTeams and create an array of card objects
			EVENT_TEAMS_DATA.forEach(et => {

				const TEAM_DATA = TEAMS_DATA.find(t => t.entityId === et.eventTeamTeamId)
				const EVENT_TEAM_FIGURES_DATA = EVENT_TEAMS_FIGURES_DATA.find(etf => etf.teamId === et.eventTeamTeamId)

                // set the quota
                et.eventTeamQuota = TEAM_DATA.teamQuota

				const TABLE_OBJECT = {
					eventTeamData: {
						...et,
					},
					eventTeamFiguresData: {
						...EVENT_TEAM_FIGURES_DATA,
					},
					teamData: {
						...TEAM_DATA,
					},
				}

				cardsData.push(TABLE_OBJECT)
			})

			// Filter by Teams
			if (t.filterByTeams.length) {

				cardsData = cardsData.filter(cd => {
					return t.filterByTeams.some(t => t.entityId === cd.teamData.entityId)
				})

			}

			return cardsData?.sort((a, b) => a.teamData.teamName > b.teamData.teamName ? 1 : -1)
		},

		/**
		 * Computed Event Figures
		 *
		 * Calculate and return the total figures for the Event.
		 *
		 * @returns {{numberUnavailable: (string|number), numberAvailable: (string|number), numberBalance: (string|number), numberNoResponse: (string|number), numberConfirmed: (string|number), numberNotRequired: (string|number), numberReserved: (string|number)}} - an object containing the total figures for the Event
		 */
		computedEventFigures() {
			const t = this
			const CARDS_DATA = t.computedCardsData
			let numberConfirmed = t.selectedEvent?.entityId ? 0 : '-'
			let numberAvailable = t.selectedEvent?.entityId ? 0 : '-'
			let numberReserved = t.selectedEvent?.entityId ? 0 : '-'
			let numberUnavailable = t.selectedEvent?.entityId ? 0 : '-'
			let numberNotRequired = t.selectedEvent?.entityId ? 0 : '-'
			let numberNoResponse = t.selectedEvent?.entityId ? 0 : '-'
			let numberBalance = t.selectedEvent?.entityId ? 0 : '-'


			// Calculate the total number Confirmed members from all the numberOfConfirmedMembers field in CARDS_DATA objects
			if (t.selectedEvent?.entityId) {
				CARDS_DATA.forEach(cardData => {
					numberConfirmed += cardData.eventTeamFiguresData.numberOfConfirmedMembers
					numberAvailable += cardData.eventTeamFiguresData.numberOfAvailableMembers
					numberReserved += cardData.eventTeamFiguresData.numberOfReservedMembers
					numberUnavailable += cardData.eventTeamFiguresData.numberOfUnavailableMembers
					numberNotRequired += cardData.eventTeamFiguresData.numberOfNotRequiredMembers
					numberNoResponse += cardData.eventTeamFiguresData.numberOfNoResponseMembers
					numberBalance += cardData.eventTeamFiguresData.teamBalance
				})
			}

			return {
				numberConfirmed,
				numberAvailable,
				numberReserved,
				numberUnavailable,
				numberNotRequired,
				numberNoResponse,
				numberBalance
			}
		},

		/**
		 * Computed Teams Data
		 *
		 * Return an array of Teams that have been added to this Event.
		 *
		 * @returns {array} - array of Team object
		 */
		computedTeamsData() {
			const t = this
			const EVENT_TEAMS_DATA = t.eventTeamsData
			const TEAMS_DATA = t.teamsData

			return TEAMS_DATA.filter(t => {
				return EVENT_TEAMS_DATA.find(et => et.eventTeamTeamId === t.entityId)
			})
		},

	},

	methods: {

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'New') t.isAddLocationVisible = true
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadEventsData(),
				t.loadTeamsData()
			])

			t.isLoading = false
		},

		/**
		 * Load Events Data
		 *
		 * Load Live Events data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadEventsData() {
			const t = this

			// Fetch the data
			// const RESPONSE = await t.MIX_redis_getEventsWhere({whereKey: 'eventStatus', whereValue: 'Live'})
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'Event',
				[{whereKey: 'eventStatus', whereValue: 'Live'}],
				[
					{field: 'eventName'},
					{field: 'eventDate'}
				]

			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Events: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.eventsData = RESPONSE.data?.sort((a, b) => a.eventDate > b.eventDate ? 1 : -1)
		},

		/**
		 * Load EventTeams Data
		 *
		 * Load EventTeams data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadEventTeamsData(eventId) {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getEventTeamsByEvent(eventId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting EventTeams: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.eventTeamsData = RESPONSE.data
		},

		/**
		 * Load EventTeam Figures Data
		 *
		 * Load EventTeams figures data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadEventTeamsFiguresData(eventId) {
			const t = this

			t.isLoading = true

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getEventTeamsFiguresByEvent(eventId)

			t.isLoading = false

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting EventTeams figures: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.eventTeamsFiguresData = RESPONSE.data
		},

		/**
		 * Load Teams Data
		 *
		 * Load Teams data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadTeamsData() {
			const t = this

			// Fetch the data
			// const RESPONSE = await t.MIX_redis_getAll('team')
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'Team',
				[],
				[{field: 'teamName'},
                 {field: 'teamQuota'}
                ]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Teams: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.teamsData = RESPONSE.data
		},

	},

	watch: {

		'selectedEvent'(selectedEvent) {
			const t = this

			// Load event data
			t.loadEventTeamsData(selectedEvent.entityId)
			t.loadEventTeamsFiguresData(selectedEvent.entityId)

		}

	},

	async mounted() {
		const t = this

		await t.loadData()

		// Use a setTimeout to reload the data every minute
		t.refreshInterval = setInterval(async () => {
			await t.loadEventTeamsData(t.selectedEvent.entityId)
			await t.loadEventTeamsFiguresData(t.selectedEvent.entityId)
		}, 1*60*1000)
	},

	beforeDestroy() {
		const t = this

		// Clear the interval
		clearInterval(t.refreshInterval)
	}

}
</script>

<style scoped>
.preEventDashboard-cardGrid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-gap: 16px;
}
</style>
