<template>
    <page-loading-animation v-if="isLoading" :is-loading="isLoading" />

    <!--Page Content-->
    <div v-else>
        <!--Header-->
        <page-title
            :divider="true"
            icon="users"
            info="View and administer user accounts"
            title="Users"
        />

        <!--Action Bar-->
        <div class="d-flex align-center mt-4">
            <!--Search-->
            <app-form-field
                form-type="textInput"
                append-icon="icons8-search"
                class="mr-4"
                :clearable="true"
                label="Name or Steward Number"
                style="width: 100%"
                v-model.trim="searchByNameOrNumber"
            />

            <!--Add Button-->
            <!--<app-btn class="mr-4" icon="add" label="Add"/>-->

            <!--Filter Button - with a numbered badge-->
            <div style="position: relative">
                <app-btn
                    @click.native="toggleFiltersVisibility"
                    class="mr-4"
                    color="appWhite"
                    icon="filter"
                    icon-color="primary"
                />
                <span v-if="computedNumberOfActiveFilters > 0" class="badge">{{
                    computedNumberOfActiveFilters
                }}</span>
            </div>

            <!--More Actions Menu-->
            <more-actions-menu
                @emitMenuSelection="handleEmittedMenuSelection"
                :menuList="moreActionsMenuOption"
            />
        </div>

        <!--Clear Filters-->
        <div
            v-if="computedNumberOfActiveFilters > 0"
            class="d-flex justify-end mt-4"
        >
            <app-btn
                @click.native="clearFilters"
                icon="close"
                label="Clear Filters"
            />
        </div>

        <!--Table-->
        <v-data-table
            v-if="$vuetify.breakpoint.width >= 600"
            class="appWhite rounded-lg mt-4"
            :headers="computedHeaders"
            :items="computedTableData"
        >
            <!--ID-->
            <template v-slot:item.id="{ item }">
                <app-text size="small">{{ item.id }}</app-text>
            </template>

            <!--Status-->
            <template v-slot:item.userStatus="{ item }">
                <div class="d-flex justify-space-between">
                    <app-icon
                        v-if="item.userData.userStatus === 'Pending'"
                        color="orange"
                        icon="pending"
                        size="32"
                        :title="item.userData.userStatus"
                    />
                    <app-icon
                        v-if="item.userData.userStatus === 'Approved'"
                        color="green"
                        icon="success"
                        size="32"
                        :title="item.userData.userStatus"
                    />
                    <app-icon
                        v-if="item.userData.userStatus === 'Rejected'"
                        color="red"
                        icon="onHold"
                        size="32"
                        :title="item.userData.userStatus"
                    />
                    <app-icon
                        v-if="item.userData.userStatus === 'Archived'"
                        color="red"
                        icon="archived"
                        size="32"
                        :title="item.userData.userStatus"
                    />
                    <app-icon
                        v-if="item.userData.userStatus === 'Suspended'"
                        color="red"
                        icon="onHold"
                        size="32"
                        :title="item.userData.userStatus"
                    />
                    <app-icon
                        v-if="item.userData.userStatus === 'Deleted'"
                        color="red"
                        icon="userDeleted"
                        size="32"
                        :title="item.userData.userStatus"
                    />

                    <div class="mr-2" />

                    <app-icon
                        v-if="item.userData.userSwappStatus === 'Out'"
                        color="grey"
                        icon="qrCode"
                        size="32"
                        :title="`SWAPPed ${item.userData.userSwappStatus}`"
                    />
                    <app-icon
                        v-if="item.userData.userSwappStatus === 'In'"
                        color="green"
                        icon="qrCode"
                        size="32"
                        :title="`SWAPPed ${item.userData.userSwappStatus}`"
                    />
                </div>
            </template>

            <!--Name-->
            <template v-slot:item.userName="{ item }">
                <app-text size="small">{{ item.userData.userName }}</app-text>
                <app-text
                    v-if="item.userData.userInternalIdNumber !== 'S00000'"
                    color="grey9"
                    size="small"
                    >{{ item.userData.userInternalIdNumber }}
                </app-text>
            </template>

            <!--Type-->
            <template v-slot:item.userLevel="{ item }">
                <app-text size="small">{{ item.userData.userLevel }}</app-text>
            </template>

            <!--Position-->
            <template v-slot:item.userPosition="{ item }">
                <app-text size="small">{{
                    item.userData.userPosition
                }}</app-text>
            </template>

            <!--Team-->
            <template v-slot:item.userTeams="{ item }">
                <!--If the user is not a Steward-->
                <app-text
                    v-if="item.userData.userType !== 'Steward'"
                    color="greyD"
                    size="small"
                    >N/A</app-text
                >

                <!--If the user is a Steward and is not on a Team-->
                <app-text
                    v-if="
                        item.userData.userType === 'Steward' &&
                        !item.userData.userTeams.length
                    "
                    color="greyD"
                    size="small"
                >
                    Not assigned
                </app-text>

                <!--If the user is a Steward and is on a Team-->
                <div
                    v-if="
                        item.userData.userType === 'Steward' &&
                        item.userData.userTeams.length
                    "
                >
                    <app-text
                        v-for="team in item.teamData"
                        :key="team.entityId"
                        size="small"
                    >
                        {{ team.teamName }}
                    </app-text>
                </div>
            </template>

            <!--Action Button-->
            <template v-slot:item.action="{ item }">
                <div class="d-flex justify-end">
                    <app-icon
                        v-if="checkForMissingProfileData(item.userData)"
                        class="flash"
                        color="red"
                        icon="error"
                        title="Missing data"
                    />
                    <app-icon
                        @click.native="handleOpenRightPanel(item)"
                        class="cursorPointer"
                        color="primary"
                        icon="arrowForward"
                    />
                </div>
            </template>
        </v-data-table>

        <!--Mobile Cards-->
        <div v-if="$vuetify.breakpoint.width < 600">
            <!--No data message-->
            <app-text
                v-if="!computedPaginatedData.length"
                class="text-center mt-4"
                color="grey9"
                >You have no Users to view
            </app-text>

            <users-mobile-card
                v-for="item in computedPaginatedData"
                :key="item.userData.entityId"
                @click.native="handleOpenRightPanel(item)"
                :user="item"
            />

            <!--Pagination Controls-->
            <div class="d-flex align-center justify-center mt-4">
                <app-btn
                    @click.native="prevPage"
                    :disabled="currentPage === 1"
                    label="Previous"
                />
                <app-text class="mx-2"
                    >{{ currentPage }} / {{ computedTotalPages }}</app-text
                >
                <app-btn
                    @click.native="nextPage"
                    :disabled="currentPage === computedTotalPages"
                    label="Next"
                />
            </div>
        </div>

        <!--Filters Panel ------------------------------------------------------------------------------------------ -->
        <filter-panel
            :is-filters-panel-visible="isFiltersPanelVisible"
            @toggle="toggleFiltersVisibility"
        >
            <div class="pa-4">
                <!--Statuses-->
                <page-break-title title="Statuses" />
                <div
                    class="mt-4"
                    style="
                        display: grid;
                        grid-template-columns: repeat(
                            auto-fit,
                            minmax(120px, 1fr)
                        );
                        grid-gap: 16px;
                    "
                >
                    <app-btn
                        @click.native="handleFilterStatusSelection('Pending')"
                        :block="true"
                        color="orange"
                        label="Pending"
                        :outlined="!filterByStatuses.includes('Pending')"
                    />
                    <app-btn
                        @click.native="handleFilterStatusSelection('Approved')"
                        :block="true"
                        color="green"
                        label="Approved"
                        :outlined="!filterByStatuses.includes('Approved')"
                    />
                    <app-btn
                        @click.native="handleFilterStatusSelection('Archived')"
                        :block="true"
                        color="grey"
                        label="Archived"
                        :outlined="!filterByStatuses.includes('Archived')"
                    />
                    <app-btn
                        @click.native="handleFilterStatusSelection('Rejected')"
                        :block="true"
                        color="red"
                        label="Rejected"
                        :outlined="!filterByStatuses.includes('Rejected')"
                    />
                    <app-btn
                        @click.native="handleFilterStatusSelection('Suspended')"
                        :block="true"
                        color="red"
                        label="Suspended"
                        :outlined="!filterByStatuses.includes('Suspended')"
                    />
                </div>

                <!--Position-->
                <page-break-title class="mt-8" title="Position" />
                <div class="mt-4">
                    <app-form-field
                        form-type="textInput"
                        label="Position"
                        v-model="filterByPosition"
                    />
                </div>

                <!--Pay Grades-->
                <page-break-title class="mt-8" title="Pay Grades" />
                <div class="mt-4">
                    <app-form-field
                        form-type="autoComplete"
                        :deletable-chips="true"
                        :items="userPayGradeOptions"
                        label="Pay Grades"
                        :multiple="true"
                        :small-chips="true"
                        v-model="filterByPayGrades"
                    />
                </div>

                <!--Team-->
                <page-break-title class="mt-8" title="Team" />
                <div class="mt-4">
                    <app-form-field
                        form-type="textInput"
                        label="Team"
                        v-model="filterByTeam"
                    />
                </div>

                <!--Types-->
                <page-break-title class="mt-8" title="Types" />
                <div class="mt-4">
                    <app-form-field
                        form-type="autoComplete"
                        :deletable-chips="true"
                        :items="userTypeOptions"
                        label="Types"
                        :multiple="true"
                        :small-chips="true"
                        v-model="filterByTypes"
                    />
                </div>

                <!--Roles-->
                <page-break-title class="mt-8" title="Roles" />
                <div class="mt-4">
                    <app-form-field
                        form-type="autoComplete"
                        :deletable-chips="true"
                        :items="[
                            'Admin',
                            'Deputy',
                            'Manager',
                            'Supervisor',
                            'User',
                        ]"
                        label="Roles"
                        :multiple="true"
                        :small-chips="true"
                        v-model="filterByRoles"
                    />
                </div>
            </div>
        </filter-panel>

        <!--Right Side --------------------------------------------------------------------------------------------- -->
        <transition
            enter-active-class="animate__animated animate__fadeInRight animate__faster"
            leave-active-class="animate__animated animate__fadeOutRight animate__faster"
            mode="out-in"
        >
            <div
                v-if="isRightPanelVisible"
                class="appGrey formShadow"
                style="
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    overflow: auto;
                "
                :style="
                    $vuetify.breakpoint.width < 600
                        ? 'width: 100%'
                        : 'width: 50%'
                "
            >
                <!--Header-->
                <div class="d-flex align-center primary pa-4">
                    <app-text color="appWhite" size="normal">{{
                        selectedItem.userData.userName
                    }}</app-text>
                    <v-spacer />
                    <app-icon
                        @click.native="closeRightPanel"
                        class="cursorPointer"
                        color="white"
                        icon="close"
                        size="32"
                    />
                </div>

                <!--Action Bar-->
                <div class="d-flex align-center pa-4">
                    <!--User Status-->
                    <div class="d-flex align-center">
                        <app-icon
                            v-if="
                                selectedItem &&
                                selectedItem.userData.userStatus === 'Pending'
                            "
                            class="flash mr-4"
                            color="orange"
                            icon="pending"
                            size="48"
                        />
                        <app-icon
                            v-if="
                                selectedItem &&
                                selectedItem.userData.userStatus === 'Approved'
                            "
                            class="mr-4"
                            color="green"
                            icon="success"
                            size="48"
                        />
                        <app-icon
                            v-if="
                                selectedItem &&
                                selectedItem.userData.userStatus === 'Rejected'
                            "
                            class="mr-4"
                            color="red"
                            icon="onHold"
                            size="48"
                        />
                        <app-icon
                            v-if="
                                selectedItem &&
                                selectedItem.userData.userStatus === 'Suspended'
                            "
                            class="mr-4"
                            color="red"
                            icon="onHold"
                            size="48"
                        />
                    </div>

                    <v-spacer />

                    <!--Action Buttons (Overview)-->
                    <edit-icon
                        v-if="tabs === 'overview'"
                        @click.native="editItem"
                        :isActive="!isReadOnly"
                    />
                    <delete-icon
                        v-if="
                            tabs === 'overview' &&
                            ['Staff-Admin'].includes(
                                MIX_getCurrentUser().userLevel
                            )
                        "
                        @emitDeleteItem="deleteUser(selectedItem.userData)"
                        itemName="userName"
                        :selectedItem="selectedItem.userData"
                    />

                    <!--Action Buttons (Qualification Form)-->
                    <edit-icon
                        v-if="
                            ['Staff-Admin', 'Staff-Manager'].includes(
                                MIX_getCurrentUser().userLevel
                            ) &&
                            tabs === 'qualifications' &&
                            isQualificationFormVisible
                        "
                        @click.native="editItem"
                        :isActive="!isReadOnly"
                    />
                    <delete-icon
                        v-if="
                            ['Staff-Admin', 'Staff-Manager'].includes(
                                MIX_getCurrentUser().userLevel
                            ) &&
                            tabs === 'qualifications' &&
                            isQualificationFormVisible
                        "
                        @emitDeleteItem="
                            deleteQualification(
                                selectedQualification?.qualificationData
                            )
                        "
                        itemName="qualificationName"
                        :selectedItem="selectedQualification?.qualificationData"
                    />
                </div>

                <!--Tabs-->
                <v-tabs v-model="tabs">
                    <v-tab href="#overview">
                        <app-text size="small">Overview</app-text>
                    </v-tab>
                    <v-tab
                        v-if="MIX_isFeatureAllowed('Qualifications')"
                        href="#qualifications"
                    >
                        <app-text size="small">Qualifications</app-text>
                    </v-tab>
                    <v-tab href="#swappHistory">
                        <app-text size="small">SWAPP History</app-text>
                    </v-tab>
                </v-tabs>

                <!--Tabs Content-->
                <v-tabs-items v-model="tabs">
                    <v-tab-item value="overview">
                        <user-form
                            @emitReloadPage="emittedReloadPage"
                            class="pa-4"
                            :fullUserData="selectedItem"
                            :isReadOnly="isReadOnly"
                        />
                    </v-tab-item>

                    <v-tab-item value="qualifications">
                        <user-qualifications-table
                            v-if="!isQualificationFormVisible"
                            @emitHandleAddQualification="
                                handleAddNewQualification
                            "
                            @emitQualificationSelection="
                                openSelectedQualification
                            "
                            @emitReloadData="emittedReloadData"
                            class="appGrey px-4"
                            :full-user-data="selectedItem"
                            :isReadOnly="isReadOnly"
                            parentView="users"
                            :qualification-categories-data="
                                qualificationCategoriesData
                            "
                            :qualification-presets-data="
                                qualificationPresetsData
                            "
                        />

                        <user-qualification-form
                            v-if="isQualificationFormVisible"
                            @closeQualificationForm="
                                toggleQualificationFormVisibility
                            "
                            @emitReloadData="emittedReloadData"
                            class="pa-4"
                            :formData="selectedQualification"
                            :full-user-data="selectedItem"
                            :isReadOnly="isReadOnly"
                            parentView="users"
                            :qualification-categories-data="
                                qualificationCategoriesData
                            "
                            :qualification-presets-data="
                                qualificationPresetsData
                            "
                        />
                    </v-tab-item>

                    <v-tab-item value="swappHistory">
                        <user-swapp-history
                            class="appGrey pa-4"
                            :user-data="selectedItem"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </transition>
    </div>
</template>

<script>
///////////////////
//// ! Users ! ////
///////////////////

import UserForm from "@/views/users/userForm/UserForm";
import UserQualificationForm from "@/views/users/userQualificationForm/UserQualificationForm";
import UserQualificationsTable from "@/views/users/userQualificationsTable/UserQualificationsTable";
import UsersMobileCard from "@/views/users/userMobileCard/UserMobileCard";
import UserSwappHistory from "@/views/users/userSwappHistory/UserSwappHistory.vue";

export default {
    name: "Users",

    components: {
        UserSwappHistory,
        UserForm,
        UserQualificationForm,
        UserQualificationsTable,
        UsersMobileCard,
    },

    data: () => ({
        filterByPayGrades: [],
        filterByPosition: "",
        filterByRoles: [],
        filterByStatuses: [],
        filterByTeam: "",
        filterByTypes: [],
        isFiltersPanelVisible: false,
        isLoading: true,
        isQualificationFormVisible: false,
        isReadOnly: false,
        isRightPanelVisible: false,

        moreActionsMenuOption: [{ name: "Export", icon: "export" }],
        searchByNameOrNumber: "",
        searchByTeam: "",
        searchByLevel: "",
        selectedItem: {},
        tableHeaders: [
            {
                text: "ID",
                value: "id",
                align: "start",
                sortable: false,
                hidden: true,
            },
            {
                text: "Status",
                value: "userStatus",
                align: "center",
                sortable: false,
                width: "10px",
            },
            {
                text: "Name",
                value: "userName",
                align: "start",
                sortable: false,
            },
            {
                text: "Level",
                value: "userLevel",
                align: "start",
                sortable: false,
            },
            {
                text: "Position",
                value: "userPosition",
                align: "start",
                sortable: false,
            },
            {
                text: "Teams",
                value: "userTeams",
                align: "start",
                sortable: false,
            },
            {
                text: "",
                value: "action",
                align: "right",
                sortable: false,
                width: "48px",
            },
        ],
        tabs: "",

        currentPage: 1,
        itemsPerPage: 10,

        // Data
        fullUsersData: [],
        qualificationCategoriesData: [],
        qualificationPresetsData: [],
        selectedQualification: {},
        teamsData: [],
        usersData: [],
    }),

    computed: {
        /**
         * Computed Export CSV
         *
         * Return the data and headers for the CSV export
         *
         * @returns {{headers: {}, data: *[]}}
         */
        computedExportCSV() {
            const t = this;
            let data = [];
            let headers = {};

            // Add the readable headers for the CSV columns
            headers = {
                entityId: "ID",
                userStatus: "Status",
                userInternalIdNumber: "Internal ID",
                userName: "Name",
                userEmail: "Email",         // Added email
                userTelephone: "Telephone", // Added telephone
                userPosition: "Position",
                userTeams: "Teams",
                userPayGrade: "Pay Grade",
                userType: "Type",
                userRole: "Role",
                userLevel: "Level",
            };

            // Add the data
            t.computedTableData.forEach((entry) => {
                // Get all team names with proper null checking
                const teamNames = entry?.teamData?.length
                    ? entry.teamData.map((team) => team.teamName).join(" / ")
                    : "";


                const DATA_OBJECT = {
                    entityId: entry?.userData?.entityId,
                    userStatus: entry?.userData?.userStatus,
                    userInternalIdNumber: entry?.userData?.userInternalIdNumber,
                    userName: entry?.userData?.userName,
                    userEmail: entry?.userData?.userEmail,         // Added email
                    userTelephone: entry?.userData?.userTelephone, // Added telephone
                    userPosition: entry?.userData?.userPosition,
                    userTeams: teamNames,
                    userPayGrade: entry?.userData?.userPayGrade,
                    userType: entry?.userData?.userType,
                    userRole: entry?.userData?.userRole,
                    userLevel: entry?.userData?.userLevel,
                };

                data.push(DATA_OBJECT);
            });

            return { headers, data };
        },

        /**
         * Computed Headers
         *
         * Remove hidden headers and only show the actions column to those with access.
         *
         * @returns an array of header objects
         */
        computedHeaders() {
            const t = this;
            const CURRENT_USER_DATA = t.MIX_getCurrentUser();
            let headers = t.tableHeaders;

            // Remove hidden headers
            headers = headers.filter((h) => !h.hidden);

            // Only those with access can view the actions column
            if (
                !["Staff-Admin", "Staff-Manager"].includes(
                    CURRENT_USER_DATA.userLevel
                )
            )
                headers = headers.filter((h) => h.value !== "action");

            return headers;
        },

        /**
         * Computed Number of Active Filters
         *
         * Count the number of active filters to display in the filters button badge.
         *
         * @returns {*}
         */
        computedNumberOfActiveFilters() {
            const t = this;
            return [
                t.filterByStatuses.length,
                t.filterByPayGrades.length,
                t.filterByPosition ? 1 : 0,
                t.filterByTeam ? 1 : 0,
                t.filterByTypes.length,
                t.filterByRoles.length,
            ].reduce((acc, curr) => acc + curr, 0);
        },

        /**
         * Computed Paginated Data
         *
         * Return the paginated data for the mobile view.
         * The data is sliced based on the current page and items per page.
         *
         * Note: This is only used for the mobile view, and is required so the mobile view does crash when the data is too large.
         *
         * @returns {array[{}]} an array of objects
         */
        computedPaginatedData() {
            const t = this;

            const START_PAGE = (t.currentPage - 1) * t.itemsPerPage;
            const END_PAGE = START_PAGE + t.itemsPerPage;

            return t.computedTableData.slice(START_PAGE, END_PAGE);
        },

        /**
         * Computed Table Data
         *
         * Sort and return the form data.
         *
         * @returns {array[{}]} an array of objects
         */
        computedTableData() {
            const t = this;
            let tableData = t.fullUsersData;

            // Filter out Organisation Users - only show Staff and Steward
            // tableData = tableData.filter((item) =>
            //     ["Staff", "Steward"].includes(item.userData.userType)
            // );

            // Search by Name or Internal ID
            if (t.searchByNameOrNumber) {
                t.currentPage = 1;
                const SEARCH_CRITERIA = t.searchByNameOrNumber.toUpperCase();
                tableData = tableData.filter((item) => {
                    const USER_NAME = item.userData.userName.toUpperCase();
                    const ID_NUMBER =
                        item.userData.userInternalIdNumber.toUpperCase();
                    return (
                        USER_NAME.includes(SEARCH_CRITERIA) ||
                        ID_NUMBER.includes(SEARCH_CRITERIA)
                    );
                });
            }

            // Hide "Suspended" and "Rejected" by default
            if (
                !t.filterByStatuses.includes("Suspended") &&
                !t.filterByStatuses.includes("Rejected")
            ) {
                tableData = tableData.filter(
                    (item) =>
                        item.userData.userStatus !== "Suspended" &&
                        item.userData.userStatus !== "Rejected"
                );
            }

            // Filter by Status
            if (t.filterByStatuses.length)
                tableData = tableData.filter((item) =>
                    t.filterByStatuses.includes(item.userData.userStatus)
                );

            // Filter by Position
            if (t.filterByPosition) {
                const SEARCH_CRITERIA = t.filterByPosition.toUpperCase();
                tableData = tableData.filter((item) => {
                    const USER_POSITION =
                        item.userData.userPosition.toUpperCase();
                    return USER_POSITION.includes(SEARCH_CRITERIA);
                });
            }

            // Filter by Pay Grades
            if (t.filterByPayGrades.length)
                tableData = tableData.filter((item) =>
                    t.filterByPayGrades.includes(item.userData.userPayGrade)
                );

            // Filter by Team - Updated to search across all teams
            if (t.filterByTeam) {
                const SEARCH_CRITERIA = t.filterByTeam.toUpperCase();
                tableData = tableData.filter((item) => {
                    // If no teamData, return false
                    if (!item.teamData?.length) return false;

                    // Search through all teams for a match
                    return item.teamData.some((team) =>
                        team.teamName.toUpperCase().includes(SEARCH_CRITERIA)
                    );
                });
            }

            // Filter by Types
            if (t.filterByTypes.length)
                tableData = tableData.filter((item) =>
                    t.filterByTypes.includes(item.userData.userType)
                );

            // Filter by Roles
            if (t.filterByRoles.length)
                tableData = tableData.filter((item) =>
                    t.filterByRoles.includes(item.userData.userRole)
                );

            tableData = tableData.sort((a, b) =>
                a.userData.userName > b.userData.userName ? 1 : -1
            );

            return tableData;
        },

        /**
         * Computed Current Page
         *
         * Return the current page number.
         *
         * @returns {number} - the current page number
         */
        computedTotalPages() {
            return Math.ceil(this.computedTableData.length / this.itemsPerPage);
        },
    },

    methods: {
        /**
         * Clear Filters
         *
         * Clear all the filters.
         */
        clearFilters() {
            const t = this;

            t.filterByPayGrades = [];
            t.filterByPosition = "";
            t.filterByRoles = [];
            t.filterByStatuses = [];
            t.filterByTeam = "";
            t.filterByTypes = [];
        },

        /**
         * Check For Missing Profile Data
         *
         * If the user is missing profile data, return true.
         * Data is calculated by userType.
         *
         * @param item {object} - the user object
         * @returns {boolean} - true if missing data
         */
        checkForMissingProfileData(item) {
            let isMissingData = false;

            // Staff
            if (item.userType === "Staff")
                if (!item.userPayGrade) isMissingData = true;

            // Steward
            if (item.userType === "Steward") {
                if (!item.userInternalIdNumber) isMissingData = true;
                if (!item.userPayGrade) isMissingData = true;
                if (!item.userTeams.length) isMissingData = true;
            }

            return isMissingData;
        },

        /**
         * Close Right Panel
         *
         * Close the right panel.
         */
        closeRightPanel() {
            const t = this;

            t.isQualificationFormVisible = false;
            t.isRightPanelVisible = false;
            t.selectedItem = {};
            t.selectedQualification = {};
            t.tabs = "";
        },

        /**
         * Delete Qualification
         *
         * Delete the selected Qualification.
         *
         * @param itemData {object} the item to delete
         */
        async deleteQualification(itemData) {
            const t = this;

            const RESPONSE = await t.MIX_redis_delete(
                "qualification",
                itemData.entityId,
                itemData
            );

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error("Error deleting Qualification", RESPONSE.error);
                return;
            }

            t.$sharedState.successMessage = "Deleted Qualification";

            // Reload the data
            await t.loadData();

            // Close the form
            t.toggleQualificationFormVisibility();
        },

        /**
         *
         * Delete Item
         *
         * Delete the selected document by its ID.
         *
         * @param itemData {object} the item to delete
         */
        async deleteUser(itemData) {
            const t = this;

            const RESPONSE = await t.MIX_redis_delete(
                "user",
                itemData.entityId,
                itemData
            );

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error("Error deleting User", RESPONSE.error);
                return;
            }

            t.$sharedState.successMessage = "Deleted User";

            // Reload the data
            await t.loadData();

            // Close the panel
            t.closeRightPanel();
        },

        /**
         * Edit Item
         *
         * Toggle the editability of the form.
         */
        editItem() {
            const t = this;

            t.isReadOnly = !t.isReadOnly;
        },

        /**
         * Emitted Reload Data
         *
         * Emit the reload data event to the parent component.
         */
        async emittedReloadData() {
            const t = this;

            await t.loadFullUserData(t.selectedItem.userData.userFbId);

            //Close the form
            t.isQualificationFormVisible = false;
        },

        /**
         * Emitted Reload Page
         *
         * Close the right panel and reload the data to refresh the page.
         */
        emittedReloadPage() {
            const t = this;

            t.closeRightPanel();
            t.loadData();
        },

        /**
         * Export Data
         *
         * Export the data to CSV.
         */
        exportData() {
            const t = this;

            t.MIX_exportDocuments(
                t.computedExportCSV.headers,
                "Users",
                t.computedExportCSV.data
            );
        },

        /**
         * Handle Add New Qualification
         *
         * Handle the Add New Qualification button click.
         */
        handleAddNewQualification() {
            const t = this;

            t.selectedQualification = {};
            t.isReadOnly = false;
            t.isQualificationFormVisible = true;
        },

        /**
         * Handle Emitted Menu Selection
         *
         * Take the emitted selection from the menu, and call the relevant function.
         *
         * @param selection a string-based menu option
         */
        handleEmittedMenuSelection(selection) {
            const t = this;

            if (selection === "Export") t.exportData();
        },

        /**
         * Handle Filter Status Selection
         *
         * Add or remove the selected status from the filterStatuses array.
         *
         * @param status {string} the status to add or remove
         */
        handleFilterStatusSelection(status) {
            const t = this;

            if (t.filterByStatuses.includes(status))
                t.filterByStatuses = t.filterByStatuses.filter(
                    (s) => s !== status
                );
            else t.filterByStatuses.push(status);
        },

        /**
         * Handle Open Right Panel
         *
         * Open the right panel and load the full user data.
         *
         * @param item {object} the selected item
         */
        async handleOpenRightPanel(item) {
            const t = this;

            t.isLoading = true;

            await Promise.all([t.loadFullUserData(item.userData.userFbId)]);

            t.isLoading = false;
        },

        /**
         * Load Data
         *
         * Load all the data required for the page.
         *
         * @returns {Promise<void>}
         */
        async loadData() {
            const t = this;

            t.isLoading = true;

            await Promise.all([
                t.loadFullUsersData(),
                t.loadQualificationPresetsData(),
                t.loadQualificationCategoriesData(),
            ]);

            t.isLoading = false;
        },

        /**
         * Load Full User Data
         *
         * Load User data from the database.
         *
         * @param userFbId {string} the user's Firebase ID
         * @returns {Promise<void>}
         */
        async loadFullUserData(userFbId) {
            const t = this;

            const RESPONSE = await t.MIX_redis_getFullUserData(userFbId);

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error("Error getting full User data: ", RESPONSE.error);
                return;
            }

            // Assign the data to the state
            t.fullUserData = RESPONSE.data;

            t.openRightPanel(RESPONSE.data);
        },

        /**
         * Load Full Users Data
         *
         * Load User data from the database.
         *
         * @returns {Promise<void>}
         */
        async loadFullUsersData() {
            const t = this;

            // Get all basic user data first
            const RESPONSE = await t.MIX_redis_getFullUsersData();

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error("Error getting full Users data: ", RESPONSE.error);
                return;
            }

            // Assign the data to the state
            t.fullUsersData = RESPONSE.data;

            // // For each user, get their complete data including email and telephone
            // const fullUserPromises = RESPONSE.data.map(async (user) => {
            //     const fullUserResponse = await t.MIX_redis_getFullUserData(user.userData.userFbId);
                
            //     if (fullUserResponse.hasErrors) {
            //         console.error("Error getting complete user data: ", fullUserResponse.error);
            //         return user; // Return original user data if full data fetch fails
            //     }

            //     return fullUserResponse.data;
            // });

            // // Wait for all user data to be fetched
            // const completedData = await Promise.all(fullUserPromises);

            // // Assign the complete data to the state
            // t.fullUsersData = completedData;
        },

        /**
         * Load Qualification Categories Data
         *
         * Load the required data from the database.
         *
         * @returns {Promise<void>}
         */
        async loadQualificationCategoriesData() {
            const t = this;

            // Fetch the data
            const RESPONSE = await t.MIX_redis_getAll("qualificationCategory");

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error(
                    "Error getting Qualfication data: ",
                    RESPONSE.error
                );
                t.$sharedState.errorMessage =
                    "There was a problem getting the Qualification data, please try again.";
                return;
            }
            // Assign the data
            t.qualificationCategoriesData = RESPONSE.data;
        },

        /**
         * Load Qualification Presets Data
         *
         * Load the required data from the database.
         *
         * @returns {Promise<void>}
         */
        async loadQualificationPresetsData() {
            const t = this;

            // Fetch the data
            const RESPONSE = await t.MIX_redis_getAll("qualificationPreset");

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error(
                    "Error getting Qualfication data: ",
                    RESPONSE.error
                );
                t.$sharedState.errorMessage =
                    "There was a problem getting the Qualification data, please try again.";
                return;
            }

            // Assign the data
            t.qualificationPresetsData = RESPONSE.data;
        },

        /**
         * Next Page
         *
         * Go to the next page.
         */
        nextPage() {
            const t = this;

            if (t.currentPage < t.computedTotalPages) t.currentPage += 1;
        },

        /**
         * Open Form
         *
         * Open the form dialog.
         * If no item is passed in, the form will open as new (blank).
         *
         * @param item the selected key
         */
        openRightPanel(item) {
            const t = this;

            // If an item is passed in, it's editable
            if (item.userData?.entityId) {
                t.isReadOnly = true;
                t.selectedItem = item;
            }
            // Otherwise, it's new
            else {
                t.isReadOnly = false;
                t.selectedItem = {};
            }

            t.isRightPanelVisible = true;
        },

        /**
         * Open Selected Qualification
         *
         * Open the selected Qualification.
         *
         * @param item {object} the selected item
         */
        openSelectedQualification(item) {
            const t = this;

            //Set the selected qualification
            t.selectedQualification = item;

            t.isQualificationFormVisible = true;
        },

        /**
         * Prev Page
         *
         * Go to the previous page.
         */
        prevPage() {
            const t = this;

            if (t.currentPage > 1) t.currentPage -= 1;
        },

        /**
         * Toggle Qualification Form Visibility
         *
         * Toggle the Qualification form visibility between true and false.
         */
        toggleQualificationFormVisibility() {
            const t = this;

            t.isQualificationFormVisible = !t.isQualificationFormVisible;
        },

        /**
         * Toggle Filters Visibility
         *
         * Toggle the visibility of the filters panel.
         */
        toggleFiltersVisibility() {
            const t = this;

            t.isFiltersPanelVisible = !t.isFiltersPanelVisible;
        },
    },

    async mounted() {
        const t = this;

        await t.loadData();
    },
};
</script>

<style scoped></style>
